// chakra imports
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import { NavLink } from "react-router-dom";
import { MdOutlineLogout, MdOutlineWarningAmber } from "react-icons/md";

import { useSettings } from "contexts/SettingsProvider";
import { useAuth } from "contexts/SessionMonitorProvider";
function SidebarContent(props) {
  const { publicSettings: settings } = useSettings();
  const { routes } = props;
  const { logout } = useAuth();
  // SIDEBAR
  return (
    <VStack id="sidebar_content" height="100vh" pt="10px">
      <Brand />
      <VStack
        direction="column"
        mb="auto"
        mt="8px"
        flexGrow={1}
        width={"100%"}
        px={4}
      >
        <VStack px="0px" alignItems="beginning" gap={0} height="100%" w="100%">
          <Links routes={routes} />
          <Box className="category">
            <NavLink onClick={logout} className="pending">
              <HStack>
                <HStack gap={2}>
                  <MdOutlineLogout style={{ width: "25px" }} />
                  <Text>Logout</Text>
                </HStack>
              </HStack>
            </NavLink>
          </Box>
        </VStack>
        <HStack
          justifyContent={"center"}
          fontSize="xs"
          opacity="0.5"
          pb={1}
          width="100%"
        >
          {settings && process.env.REACT_APP_VERSION !== settings.appVersion ? (
            <MdOutlineWarningAmber
              color="red"
              title={`APP:${process.env.REACT_APP_VERSION} API:${settings.appVersion}`}
            />
          ) : null}
          <Text
            fontSize={12}
            color="whiteAlpha.500"
            title={`APP: ${process.env.REACT_APP_VERSION}`}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "40%",
            }}
          >
            APP: {process.env.REACT_APP_VERSION}
          </Text>

          <Text
            title={`Database environment`}
            fontSize={12}
            color="whiteAlpha.500"
          >
            DB: {settings?.dbEnv}
          </Text>
        </HStack>
      </VStack>
    </VStack>
  );
}

export default SidebarContent;
