import {
  MdCancel,
  MdCheck,
  MdClose,
  MdOutlineSync,
  MdDownload,
  MdOutgoingMail,
  MdOutlineRequestPage,
  MdOutlineWarningAmber,
  MdSchedule,
  MdSend,
} from "react-icons/md";
import {
  Badge,
  Checkbox,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Client } from "api/axios";
import ConfirmationModal from "components/modals/Confirmation";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import CopyTradingReportModal from "views/admin/clients/components/information/CopyTradingReportModal";
import {
  CsvDownloadButton,
  jsonToCsvTransformer,
} from "components/csvDownloadButton";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import { ALERT_MESSAGE } from "../../../../variables/message";
import { ModalManualKyc } from "./ModalManualKyc";
import { PassStats } from "./PassStats";
import ActionButton from "components/ActionButton";
import Notes from "components/Notes";
import FtmsBreachesTable from "components/ftmsBreachesTable";
import { useSettings } from "contexts/SettingsProvider";
import { useAuth } from "contexts/SessionMonitorProvider";
import { isPassLocked } from "utils/lockCheck";

export const currencyFormatNoFracOptions = {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
};

const programs = ["Experienced", "Premium", "Advanced", "Prestige", "Master"];

const getProgram = (description) => {
  return programs.find((program) =>
    description.toLowerCase().includes(program.toLowerCase())
  );
};

const getPassTitle = (account) => {
  if (account.plan) {
    const program = getProgram(account.plan.description);
    const typeLc = account.plan.type.toLowerCase();
    if (typeLc === "initial evaluation") {
      return `${program} Phase 1`;
    } else if (typeLc === "final evaluation") {
      return `${program} Phase 2`;
    } else if (typeLc === "single stage evaluation") {
      return `Single Phase ${program}`;
    }
  } else {
    const program = getProgram(account.description);
    if (program === "Experienced") {
      return `Single Phase ${program}`;
    } else if (
      program === "Premium" ||
      program === "Advanced" ||
      program === "Prestige"
    ) {
      const phase = account.type.includes("1") ? "Phase 1" : "Phase 2";
      return `${program} ${phase}`;
    }
  }
};

export const ExpandedRow = ({ row }) => {
  const { publicSettings: settings } = useSettings();
  const account = row.original;
  const pass = row.original.pass;

  const passTitle = getPassTitle(account);
  const parentAccount = account.metadata?.parentAccount;
  const parentAccountPassTitle = parentAccount
    ? getPassTitle(parentAccount)
    : null;

  const clientMeta = account.client?.metadata;

  const processManually = useDisclosure();
  const approve = useDisclosure();
  const hold = useDisclosure();
  const deny = useDisclosure();
  const copytradingReport = useDisclosure();
  const manualApproveKyc = useDisclosure();
  const sendKycInvite = useDisclosure();
  const manualApproveContracted = useDisclosure();
  const sendContractedInvite = useDisclosure();
  const sendRiskReviewEmail = useDisclosure();
  const manualLiveAccountIssued = useDisclosure();
  const accountSyncModal = useDisclosure();

  const queryClient = useQueryClient();
  const { auth } = useAuth();
  const isLocked = useMemo(() => {
    return isPassLocked(
      row.original,
      settings.crm?.clientLockTimeoutMs || 300000,
      auth.user
    );
  }, [row, settings, auth]);
  const addNoteForAccount = (message) => {
    const client = Client(true);
    return client.post(`/api/trade-accounts/${account.id}/notes`, { message });
  };

  const deleteNoteForAccount = (noteId) => {
    const client = Client(true);
    client.delete(`/api/notes/${noteId}`).then(refetchNotes);
  };

  const getNotesForAccount = async () => {
    const accountId = account.id;
    const client = Client(true);
    const queryParameters = new URLSearchParams({
      page: notesPagination.page,
      limit: notesPagination.limit,
    });
    if (notesFilters && notesFilters.filter) {
      queryParameters.append("filter", notesFilters.filter);
    }
    if (
      notesFilters?.filterActionsSelected &&
      notesFilters?.filterActionsSelected.length
    ) {
      notesFilters.filterActionsSelected.forEach((element) => {
        queryParameters.append("filterAction", element);
      });
    }

    const result = await client.get(
      `/api/trade-accounts/${accountId}/notes?${queryParameters.toString()}`
    );

    return result.data;
  };

  const [notesFilters, setNotesFilters] = useState({ filter: "" });

  const [notesPagination, setNotesPagination] = useState({
    limit: 10,
    page: 1,
  });

  const {
    data: notesData,
    isLoading: notesLoading,
    refetch: refetchNotes,
  } = useQuery({
    queryKey: [
      "notes",
      account.id,
      notesFilters,
      notesPagination.page,
      notesPagination.limit,
    ],
    queryFn: getNotesForAccount,
  });

  useEffect(() => {
    if (!notesData || !notesData.docs) return;
    const { docs, ...rest } = notesData;

    setNotesPagination({ ...rest });
  }, [notesData]);

  const { mutate: addNote } = useMutation({
    mutationFn: addNoteForAccount,
    onSuccess: refetchNotes,
  });

  const { mutate: deleteNote } = useMutation({
    mutationFn: deleteNoteForAccount,
    onSuccess: refetchNotes,
  });

  const submitManualLiveAccount = async (data) => {
    const client = Client(true);
    return await client.post(`/api/passes/${account.id}/live-account`, data);
  };

  const lastReportWithTrades = useMemo(() => {
    if (!account.report) return null;
    return account.report.copyTradeReports
      .filter((r) => r.analyzedTrades > 0)
      .at(-1);
  }, [account.report]);

  // holds info if report has been viewed
  const copyTradingReportNeedsViewing = useMemo(() => {
    if (!lastReportWithTrades || pass.status !== "new") return false;

    return (
      localStorage.getItem(`copytradingReportViewed-${account.id}`) === null
    );
  }, [lastReportWithTrades, account.id, pass.status]);

  useEffect(() => {
    if (copytradingReport.isOpen) {
      // mark report as viewed
      localStorage.setItem(
        `copytradingReportViewed-${account.id}`,
        new Date().getTime()
      );
    }
  }, [copytradingReport.isOpen, account.id]);

  const manualLiveAccount = useMutation({
    mutationFn: submitManualLiveAccount,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.LIVE_ACCOUNT_ISSUED_EMAIL_SENT);
    },
  });

  const submitPassDecision = async (data) => {
    const client = Client(true);
    await client.patch(`/api/passes/${account.id}`, data);
  };
  const postRiskReviewEmail = async (data) => {
    const client = Client(true);
    await client.post(`/api/passes/${account.id}/send-email/risk-review`, data);
  };

  const postAccountSync = async (data) => {
    const client = Client(true);
    await client.post(`/api/trade-accounts/${account.id}/sync`, data);
  };

  const passDecision = useMutation({
    mutationFn: submitPassDecision,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.PASS_ACTION_SUCCESS);

      await queryClient.invalidateQueries({ queryKey: ["passes"] });
    },
  });
  const riskReviewEmail = useMutation({
    mutationFn: postRiskReviewEmail,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.PASS_ACTION_SUCCESS);
      await queryClient.invalidateQueries({
        queryKey: [
          "notes",
          account.id,
          notesFilters,
          notesPagination.page,
          notesPagination.limit,
        ],
      });
    },
  });
  const accountSync = useMutation({
    mutationFn: postAccountSync,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.OPERATION_SUCCESS);
    },
  });

  const submitClientAction = async ({ target, action, ...data }) => {
    const client = Client(true);
    await client.post(
      `/api/client/${account.client.id}/${target}/${action}`,
      data
    );
  };

  const clientAction = useMutation({
    mutationFn: submitClientAction,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.CLIENT_ACTION_SUCCESS);

      await queryClient.invalidateQueries({ queryKey: ["passes"] });
    },
  });

  let actions = [];

  if (pass.status !== "completed" && pass.status !== "manual") {
    actions.push(
      <ActionButton
        size="sm"
        key={"processManually"}
        colorScheme="red"
        onClick={processManually.onOpen}
        leftIcon={<MdCancel />}
      >
        Process manually
      </ActionButton>
    );
  }
  if (pass.status === "new") {
    if (lastReportWithTrades) {
      actions.push(
        <ActionButton
          size="sm"
          key={"viewReport"}
          onClick={copytradingReport.onOpen}
          leftIcon={<MdOutlineRequestPage />}
        >
          View Report
        </ActionButton>
      );
    }

    const timestamp = new Date().toISOString();
    if (account.metadata?.parentAccount) {
      actions.push(
        <CsvDownloadButton
          key={"downloadParentTrades"}
          url={`/api/trade-accounts/${account.metadata?.parentAccount.id}/trades`}
          fileName={`account-${account.metadata?.parentAccount.id}-trades-${timestamp}.csv`}
          transformer={jsonToCsvTransformer}
          type="text/csv"
          size="sm"
          leftIcon={<MdDownload />}
        >
          Trades {account.metadata?.parentAccount.id}
        </CsvDownloadButton>
      );
    }
    actions.push(
      <CsvDownloadButton
        key={"downloadTrades"}
        url={`/api/trade-accounts/${account.id}/trades`}
        fileName={`account-${account.id}-trades-${timestamp}.csv`}
        transformer={jsonToCsvTransformer}
        type="text/csv"
        size="sm"
        leftIcon={<MdDownload />}
      >
        Trades {account.id}
      </CsvDownloadButton>
    );
    actions.push(
      <ActionButton
        size="sm"
        key={"riskReviewEmail"}
        onClick={sendRiskReviewEmail.onOpen}
        leftIcon={<MdOutlineWarningAmber />}
        rightIcon={<MdOutgoingMail />}
      >
        Risk Review
      </ActionButton>
    );
    actions.push([
      <ActionButton
        key={"approve"}
        size="sm"
        colorScheme="green"
        onClick={approve.onOpen}
        disabled={copyTradingReportNeedsViewing}
        leftIcon={<MdCheck />}
      >
        Approve
      </ActionButton>,
      <ActionButton
        size="sm"
        key={"hold"}
        colorScheme="yellow"
        onClick={hold.onOpen}
        disabled={copyTradingReportNeedsViewing}
        leftIcon={<MdSchedule />}
      >
        Hold
      </ActionButton>,
    ]);
  }
  if (pass.status === "inProgress") {
    if (!clientMeta?.kyc?.complete) {
      actions.push(
        <ActionButton
          size="sm"
          key="manualKyc"
          colorScheme="green"
          onClick={manualApproveKyc.onOpen}
          leftIcon={<MdCheck />}
        >
          Manual KYC
        </ActionButton>
      );
      actions.push(
        <ActionButton
          size="sm"
          key={"resendKyc"}
          colorScheme="green"
          onClick={sendKycInvite.onOpen}
          leftIcon={<MdSend />}
          rightIcon={<MdOutgoingMail />}
        >
          Resend KYC
        </ActionButton>
      );
    } else if (!clientMeta?.contracted?.complete) {
      actions.push(
        <ActionButton
          size="sm"
          key={"manualContract"}
          colorScheme="green"
          onClick={manualApproveContracted.onOpen}
          leftIcon={<MdCheck />}
        >
          Manual Contract
        </ActionButton>
      );
      actions.push(
        <ActionButton
          size="sm"
          key={"resendContract"}
          colorScheme="green"
          onClick={sendContractedInvite.onOpen}
          leftIcon={<MdSend />}
          disabled={true}
          rightIcon={<MdOutgoingMail />}
        >
          Resend Contract
        </ActionButton>
      );
    } else {
      actions.push(
        <ActionButton
          size="sm"
          key={"manualLiveAccount"}
          colorScheme="green"
          onClick={manualLiveAccountIssued.onOpen}
          leftIcon={<MdSend />}
        >
          Manual Live Account
        </ActionButton>
      );
    }
    if (lastReportWithTrades) {
      actions.push(
        <ActionButton
          size="sm"
          key={"viewReport"}
          onClick={copytradingReport.onOpen}
          leftIcon={<MdOutlineRequestPage />}
        >
          View Report
        </ActionButton>
      );
    }
  }
  if (pass.status === "manual") {
    actions.push(
      <ActionButton
        disabled={true}
        size="sm"
        key={"manualLiveAccount"}
        colorScheme="green"
        onClick={manualLiveAccountIssued.onOpen}
        leftIcon={<MdSend />}
      >
        Manual Live Account
      </ActionButton>
    );
  }
  if (pass.status === "denied" || pass.status === "hold") {
    actions.push(
      <ActionButton
        size="sm"
        key={"approve"}
        colorScheme="green"
        onClick={approve.onOpen}
        leftIcon={<MdCheck />}
      >
        Approve
      </ActionButton>
    );
    if (pass.status === "denied") {
      actions.push(
        <ActionButton
          size="sm"
          key={"hold"}
          colorScheme="yellow"
          onClick={hold.onOpen}
          leftIcon={<MdSchedule />}
        >
          Hold
        </ActionButton>
      );
    }
  }
  if (pass.status !== "completed" && pass.status !== "denied") {
    actions.push(
      <ActionButton
        size="sm"
        key="deny"
        colorScheme="red"
        onClick={deny.onOpen}
        disabled={copyTradingReportNeedsViewing}
        leftIcon={<MdClose />}
      >
        Deny
      </ActionButton>
    );
  }
  actions.push(
    <ActionButton
      size="sm"
      key={"accountSync"}
      colorScheme="green"
      onClick={accountSyncModal.onOpen}
      leftIcon={<MdOutlineSync />}
    >
      Sync Account
    </ActionButton>
  );
  return (
    <>
      <VStack alignItems={"stretch"}>
        <Stack
          direction="row"
          position="relative"
          alignContent={"space-around"}
        >
          {parentAccount ? (
            <Tabs width="100%" isLazy>
              <TabList>
                <Tab>{parentAccountPassTitle}</Tab>
                <Tab>{passTitle}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel px="0">
                  <VStack alignItems="stretch">
                    <PassStats
                      title={parentAccountPassTitle}
                      account={parentAccount}
                    />
                    {parentAccount.ftmsBreaches?.length > 0 && (
                      <GroupingContainer title="FTMS Breaches">
                        <FtmsBreachesTable data={parentAccount.ftmsBreaches} />
                      </GroupingContainer>
                    )}
                  </VStack>
                </TabPanel>
                <TabPanel px="0">
                  <VStack alignItems="stretch">
                    <PassStats title={passTitle} account={account} />
                    {account.ftmsBreaches?.length > 0 && (
                      <GroupingContainer title="FTMS Breaches">
                        <FtmsBreachesTable data={account.ftmsBreaches} />
                      </GroupingContainer>
                    )}
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : null}
          {!parentAccount ? (
            <VStack flexGrow={1}>
              <PassStats title={passTitle} account={account} />
              {account.ftmsBreaches?.length > 0 && (
                <GroupingContainer title="FTMS Breaches">
                  <FtmsBreachesTable data={account.ftmsBreaches} />
                </GroupingContainer>
              )}
            </VStack>
          ) : null}
          <GroupingContainer title="Actions" hideIfEmpty={true}>
            {actions && actions.length > 0 && !isLocked ? actions : null}
          </GroupingContainer>
        </Stack>

        <Notes
          notes={notesData?.docs || []}
          loading={notesLoading}
          addNote={addNote}
          deleteNote={deleteNote}
          flex="1"
          alignSelf="stretch"
          onFilterChange={setNotesFilters}
          pagination={notesPagination}
          onPaginationChange={({ page, limit }) => {
            setNotesPagination({ ...notesPagination, page, limit });
          }}
          filterActions={[
            {
              title: "Show System Emails",
              value: "emails",
              selected: true,
            },
            {
              title: "Show Client activity",
              value: "client",
            },
            {
              title: "Show System operations",
              value: "system",
            },
          ]}
        />
      </VStack>
      <ConfirmationModal
        header={{ children: "Process manually" }}
        body={{
          children: [
            "Are you sure you want to process this pass manually?",
            <Textarea name="message" placeholder={"Explain your Action..."} />,
          ],
        }}
        footer={{
          action: {
            text: "Approve",
            props: { colorScheme: "green" },
            onConfirmation: ({ message }) =>
              passDecision.mutate({ decision: "manual", message }),
          },
        }}
        disclosure={processManually}
      />
      <ConfirmationModal
        header={{ children: "Pass Approval" }}
        body={{
          children: [
            <Text>
              Are you sure you want to{" "}
              <Badge colorScheme="green">approve</Badge> the pass of this
              account?
            </Text>,
            <Textarea
              name="message"
              placeholder={"Explain your Decision..."}
            />,
          ],
        }}
        footer={{
          action: {
            text: "Approve",
            props: { colorScheme: "green" },
            onConfirmation: ({ message }) =>
              passDecision.mutate({ decision: "approve", message }),
          },
        }}
        disclosure={approve}
      />
      <ConfirmationModal
        header={{ children: "Pass on Hold" }}
        body={{
          children: [
            <Text>
              Are you sure you want to put the pass of this account on{" "}
              <Badge colorScheme="orange">hold</Badge> ?
            </Text>,
            <Textarea
              name="message"
              placeholder={"Explain your Decision..."}
              rules={{ required: "Required Field" }}
            />,
          ],
        }}
        footer={{
          action: {
            text: "Hold",
            props: { colorScheme: "yellow" },
            onConfirmation: ({ message }) =>
              passDecision.mutate({ decision: "hold", message }),
          },
        }}
        disclosure={hold}
      />
      <ConfirmationModal
        header={{ children: "Pass denial" }}
        body={{
          children: [
            <Text>
              Are you sure you want to <Badge colorScheme="red">deny</Badge> the
              pass of this account?
            </Text>,
            <Checkbox name="banClient" my={2}>
              Ban client
            </Checkbox>,
            <Textarea
              name="message"
              placeholder={"Explain your Decision..."}
              rules={{ required: "Required Field" }}
            />,
          ],
        }}
        footer={{
          action: {
            text: "Deny",
            props: { colorScheme: "red" },
            onConfirmation: ({ message, banClient }) =>
              passDecision.mutate({ decision: "deny", message, banClient }),
          },
        }}
        disclosure={deny}
      />
      <ModalManualKyc
        titleText={"Manually approve KYC"}
        confirmButtonText={"Approve"}
        confirmButtonColorScheme={"green"}
        isOpen={manualApproveKyc.isOpen}
        onClose={manualApproveKyc.onClose}
        onConfirm={(data) =>
          clientAction.mutate({ target: "kyc", action: "approve", ...data })
        }
      />
      {lastReportWithTrades && copytradingReport.isOpen ? (
        <CopyTradingReportModal
          report={lastReportWithTrades}
          account={account}
          onClose={copytradingReport.onClose}
        />
      ) : null}
      <ConfirmationModal
        header={{ children: "Manually approve contract" }}
        body={{
          children: [
            "Are you certain you want to approve that a contract for this client is in place?",
            <Textarea
              name="message"
              placeholder={"Explain your Action..."}
              rules={{ required: "Required Field" }}
            />,
          ],
        }}
        footer={{
          action: {
            text: "Approve",
            props: { colorScheme: "green" },
            onConfirmation: ({ message }) =>
              clientAction.mutate({
                target: "contract",
                action: "approve",
                message,
              }),
          },
        }}
        disclosure={manualApproveContracted}
      />
      <ConfirmationModal
        header={{ children: "Resend KYC invite" }}
        body={{
          children: [
            "Are you sure you want to resend the KYC invite to this client?",
            <Textarea
              name="message"
              placeholder={"Explain your Action..."}
              rules={{ required: "Required Field" }}
            />,
          ],
        }}
        footer={{
          action: {
            text: "Send",
            props: { colorScheme: "green" },
            onConfirmation: ({ message }) =>
              clientAction.mutate({
                target: "kyc",
                action: "send-invite",
                message,
              }),
          },
        }}
        disclosure={sendKycInvite}
      />
      <ConfirmationModal
        header={{ children: "Resend contract" }}
        body={{
          children: [
            "Are you sure you want to resend the contract to this client?",
            <Textarea
              name="message"
              placeholder={"Explain your Action..."}
              rules={{ required: "Required Field" }}
            />,
          ],
        }}
        footer={{
          action: {
            text: "Send",
            props: { colorScheme: "green" },
            onConfirmation: ({ message }) =>
              clientAction.mutate({
                target: "contract",
                action: "send-invite",
                message,
              }),
          },
        }}
        disclosure={sendContractedInvite}
      />
      <ConfirmationModal
        header={{ children: "Send Risk Review Email" }}
        body={{
          children: [
            "Are you sure you want to send risk review email to this client?",
            [
              "If you want to send Stronger Risk email, please enter the Margin Utilisation Percentage in the box below",
              <Input
                name="margin"
                type="number"
                color={"white"}
                placeholder={"Margin utilisation [%]"}
              />,
            ],
          ],
        }}
        footer={{
          action: {
            text: "Send",
            props: { colorScheme: "green" },
            onConfirmation: ({ margin }) => {
              if (margin) {
                margin = parseFloat(margin);
              }

              riskReviewEmail.mutate({ margin });
            },
          },
        }}
        disclosure={sendRiskReviewEmail}
      />
      <ConfirmationModal
        header={{ children: "Synchronise Account from FPFX" }}
        body={{
          children: [
            "Are you sure you want to sync account?",
            [
              "",
              "Synchronising account will update all account properties from FPFX and will generate a copytrading report.",
            ],
          ],
        }}
        footer={{
          action: {
            text: "Sync",
            props: { colorScheme: "green" },
            onConfirmation: () => {
              accountSync.mutate();
            },
          },
        }}
        disclosure={accountSyncModal}
      />
      <ConfirmationModal
        header={{ children: "Send Live Account Issued email" }}
        body={{
          children: [
            ...[
              account?.metadata?.childAccount
                ? [
                    "Live Account has been successfully issued and linked for this account.",
                  ]
                : [
                    "Are you sure you would like to manually configure the live account for this pass?",
                    <Input
                      name="accountId"
                      type="number"
                      color={"white"}
                      placeholder={"Live Account ID"}
                      rules={{ required: "Required Field" }}
                    />,
                  ],
            ],

            <RadioGroup
              name={"sendEmail"}
              key={"sendEmail"}
              rules={{ required: "Required Field" }}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
              mt={2}
              defaultValue={"yes"}
            >
              {/* add none option to force ops to make the decision */}
              <Radio value={"no"}>No Email</Radio>
              <Radio value={"yes"}>Send Live Account Issued email</Radio>
            </RadioGroup>,
          ],
        }}
        footer={{
          action: {
            text: "Submit",
            props: { colorScheme: "green" },
            onConfirmation: ({ accountId, sendEmail }) => {
              manualLiveAccount.mutate({
                accountId,
                sendEmail: sendEmail === "yes",
              });
            },
          },
        }}
        disclosure={manualLiveAccountIssued}
      />
    </>
  );
};
