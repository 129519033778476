import { Box } from "@chakra-ui/react";
// Layout components
import Sidebar from "components/sidebar/Sidebar.js";
import { useAuth } from "contexts/SessionMonitorProvider";
import { SidebarContext } from "contexts/SidebarContext";
import { useUserRoutes } from "contexts/UserRoutesProvider";
import { useMemo, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { auth } = useAuth();

  const isOpsManager = useMemo(() => {
    return (
      auth.user?.role === "opsManager" ||
      auth.user?.role === "opsAdmin" ||
      auth.user?.role === "masterAdmin"
    );
  }, [auth]);

  const isAffiliatesContributor = useMemo(() => {
    return auth.user?.role === "affiliatesContributor";
  }, [auth]);

  const { routes } = useUserRoutes();

  const getRouteObjects = (routes) => {
    return routes.map((prop, key) => {
      if (!prop.enabled) {
        return null;
      }
      if (prop.category) {
        return getRouteObjects(prop.items);
      } else {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={`${key}${prop.name}`}
          />
        );
      }
    });
  };

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes} {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100vh"
          overflowX="scroll"
          position="relative"
          maxHeight="100vh"
          w={{ base: "100%", xl: "calc( 100% - 15rem )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 15rem )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Box mx="auto" p={{ base: "20px", md: "30px" }} pe="20px" h="100%">
            <Routes>
              {getRouteObjects(routes)}
              <Route
                path={"/"}
                element={
                  isOpsManager ? (
                    <Navigate from="/" to="/admin/default" replace />
                  ) : isAffiliatesContributor ? (
                    <Navigate from="/" to="/admin/payouts" replace />
                  ) : (
                    <Navigate from="/" to="/admin/passes" replace />
                  )
                }
              />
            </Routes>
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
