import { useQuery } from "@tanstack/react-query";
import { Client } from "api/axios";
import { createContext } from "react";
const FeatureSwitchContext = createContext({});
const FEATURES_RELOAD_INTERVAL = 30000;
export const FeatureSwitchProvider = ({ children }) => {
  const { error, data: featureSwitches } = useQuery({
    queryKey: ["featureSwitches"],
    queryFn: () => Client(true).get(`/api/feature-switches`),
    initialData: { data: {} },
    refetchInterval: (data, error) => {
      if (error) return false;
      return FEATURES_RELOAD_INTERVAL;
    },
  });

  if (error) {
    console.error("featureSwitches error", error);
  }

  return (
    <FeatureSwitchContext.Provider
      value={{ featureSwitches: featureSwitches?.data || {} }}
    >
      {children}
    </FeatureSwitchContext.Provider>
  );
};

export default FeatureSwitchContext;
