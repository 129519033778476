import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Client } from "api/axios";
import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { ALERT_MESSAGE, ERROR_MESSAGE } from "variables/message";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const SessionMonitorContext = createContext({});
export const SessionMonitorProvider = ({ children }) => {
  // Get the user from local storage
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const [auth, setAuth] = useState(user);

  const navigate = useNavigate();

  const location = useLocation();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (location?.search && location.search !== "") {
      // if the token is provided in the url do nothing
      // this is to handle the case when the user is redirected from the email
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get("token");
      if (token && token !== "") {
        return;
      }
    } else if (!auth?.user) {
      // if the user is not logged in redirect to the login page
      if (location.pathname !== "/auth/sign-in") {
        navigate("/auth/sign-in");
      }
    }
  }, [auth, location, navigate]);

  useEffect(() => {
    if (auth?.user) {
      // store in local storage
      localStorage.setItem("user", JSON.stringify(auth));
    }
  }, [auth]);

  const loginRequest = ({ email, password, token }) => {
    const client = Client();
    return client.post(
      `/api/auth/login`,
      JSON.stringify({ email, password, token })
    );
  };

  const onLoginSuccess = (response) => {
    setAuth(response.data);

    // router will handle redirection to correct page by user role
    navigate("/");
  };

  const loginMutation = useMutation({
    mutationFn: loginRequest,
    onSuccess: onLoginSuccess,
  });

  const login = (email, password, token) => {
    loginMutation.mutate({ email, password, token });
  };

  const logoutRequest = async () => {
    const client = Client();
    await client.post(`/api/auth/logout`);
  };

  const logoutMutation = useMutation({
    mutationFn: logoutRequest,
    onSuccess: () => {
      setAuth({});
      localStorage.removeItem("user");
      queryClient.removeQueries();
      navigate("/auth/sign-in");
    },
  });

  const logout = useCallback(() => {
    logoutMutation.mutate();
  }, [logoutMutation]);

  const passwordResetRequest = async (password) => {
    const client = Client();
    await client.post(
      `/api/auth/change-password`,
      JSON.stringify({
        password,
      })
    );
  };

  const passwordResetMutation = useMutation({
    mutationFn: passwordResetRequest,
    onSuccess: () => {
      toast.success(ALERT_MESSAGE.USER_PASSWORD_RESET_SUCCESS);
    },
  });

  const passwordReset = (password) => {
    passwordResetMutation.mutate(password);
  };

  // Query Cache subscription
  useEffect(
    () =>
      queryClient.getQueryCache().subscribe((event) => {
        if (
          event?.action?.type === "failed" &&
          event?.action?.error?.response?.status === 401
        ) {
          toast.warning(ALERT_MESSAGE.USER_LOGIN_EXPIRED);
          logout();
        } else if (
          event?.action?.type === "failed" &&
          event?.action?.error?.response?.status !== 401 &&
          event?.action?.failureCount === 1
        ) {
          toast.error(event?.action?.error?.response?.data?.message);
        }
      }),
    [queryClient, logout]
  );

  // Mutation Cache subscription
  useEffect(
    () =>
      queryClient.getMutationCache().subscribe((event) => {
        if (event.action?.type === "error") {
          if (event.action?.error?.response?.status === 401) {
            toast.warning(ALERT_MESSAGE.USER_LOGIN_EXPIRED);
            logout();
          } else if (event?.action?.error?.response?.data?.message) {
            toast.error(event?.action?.error?.response?.data?.message);
          } else {
            toast.error(ERROR_MESSAGE.UNEXPECTED_ERROR);
          }
        }
        if (event?.action?.data?.data?.warnings) {
          event?.action?.data?.data?.warnings?.forEach((warning) => {
            toast.warning(warning);
          });
        }
      }),
    [queryClient, logout]
  );

  return (
    <SessionMonitorContext.Provider
      value={{ auth, login, logout, passwordReset }}
    >
      {children}
    </SessionMonitorContext.Provider>
  );
};

// Create the useAuth hook
export const useAuth = () => {
  const auth = useContext(SessionMonitorContext);
  if (!auth) {
    throw new Error("useAuth must be used within an SessionMonitorProvider");
  }
  return auth;
};
