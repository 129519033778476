import {
  Button,
  Flex,
  VStack,
  HStack,
  Divider,
  Heading,
  Box,
  Select,
  CheckboxGroup,
  Checkbox,
  FormLabel,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import Card from "components/card/Card.js";
import { FormInput } from "components/FormInput";
import React from "react";

export default function TechnicalSettingsForm({ config, saveConfig }) {
  const {
    handleSubmit,
    formState: { isDirty },
    control,
  } = useForm({ mode: "onBlur", defaultValues: config });

  function onSubmit(data) {
    saveConfig(data);
  }

  return (
    <Flex
      mt={4}
      alignItems="start"
      justifyContent="center"
      flexDirection="column"
    >
      <Card>
        <form id="businessForm" onSubmit={handleSubmit(onSubmit)}>
          <HStack align="stretch" wrap="wrap">
            <Box flexGrow={1}>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  Sumsub
                </Heading>
                <FormInput
                  id="sumsub.appToken"
                  label="Sumsub appToken"
                  control={control}
                  variant="floating"
                  type="password"
                />

                <FormInput
                  id="sumsub.apiKey"
                  label="Sumsub api key"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="sumsub.apiUrl"
                  label="Sumsub api URL"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="sumsub.callbackSecretKey"
                  label="Sumsub api secret key"
                  control={control}
                  variant="floating"
                  type="password"
                />
              </VStack>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  Google reCAPTCHA
                </Heading>
                <FormInput
                  id="googleReCaptcha.secret"
                  label="Google reCAPTCHA secret"
                  control={control}
                  variant="floating"
                  type="password"
                />
                <FormInput
                  id="googleReCaptcha.minimumScore"
                  label="Google reCAPTCHA minimum score"
                  control={control}
                  variant="floating"
                />
              </VStack>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  Trustpilot
                </Heading>
                <Controller
                  id="trustpilot.enabled"
                  name="trustpilot.enabled"
                  control={control}
                  render={({
                    field: { onChange, onBlur, name, ref, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      ref={ref}
                      value={value}
                    >
                      <option value="true">Enabled</option>
                      <option value="false">Disabled</option>
                    </Select>
                  )}
                ></Controller>

                <FormInput
                  id="trustpilot.apiKey"
                  label="Api Key"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="trustpilot.secret"
                  label="Secret"
                  control={control}
                  variant="floating"
                  type="password"
                />
                <FormInput
                  id="trustpilot.businessUnitId"
                  label="Unit ID"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="trustpilot.businessUserId"
                  label="User ID"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  style={{ display: "none" }}
                  id="trustpilot.productReviewTemplateId"
                  control={control}
                  type="hidden"
                  variant="floating"
                />

                <FormInput
                  id="trustpilot.accountIssuedReviewTemplateId"
                  label="Account Issued Template Id "
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="trustpilot.generalServiceReviewTemplateId"
                  label="General Template Id"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="trustpilot.withdrawalReviewTemplateId"
                  label="Withdrawal Template Id"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="trustpilot.senderEmail"
                  label="Sender email"
                  control={control}
                  type="email"
                  variant="floating"
                />
                <FormInput
                  id="trustpilot.senderName"
                  label="Sender name"
                  control={control}
                  variant="floating"
                  minLength={2}
                />
                <FormLabel>Enabled Programs</FormLabel>
                <Controller
                  id="trustpilot.allowedPrograms"
                  name="trustpilot.allowedPrograms"
                  control={control}
                  defaultValue={"false"}
                  render={({
                    field: { onChange, onBlur, name, value },
                    fieldState: { error },
                  }) => (
                    <CheckboxGroup
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      value={value}
                    >
                      <HStack>
                        <Checkbox value="experienced">Experienced</Checkbox>
                        <Checkbox value="prestige">Prestige</Checkbox>
                        <Checkbox value="premium">Premium</Checkbox>
                        <Checkbox value="advanced">Advanced</Checkbox>
                        <Checkbox value="master">Master</Checkbox>
                      </HStack>
                    </CheckboxGroup>
                  )}
                ></Controller>
              </VStack>
            </Box>

            <Box flexGrow={1}>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  Kartra
                </Heading>
                <FormInput
                  id="kartra.key"
                  label="key"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="kartra.password"
                  label="password"
                  control={control}
                  variant="floating"
                  type="password"
                />
                <FormInput
                  id="kartra.appId"
                  label="Kartra App ID"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="kartra.apiEndpoint"
                  label="Api endpoint"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="kartra.listName"
                  label="List Name"
                  control={control}
                  variant="floating"
                />
              </VStack>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  Sendgrid API
                </Heading>
                <FormInput
                  id="sendgrid.apiKey"
                  label="Sendgrid API key"
                  control={control}
                  variant="floating"
                  type="password"
                  rules={{
                    required: "This is required",
                  }}
                />
              </VStack>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  WooCommerce webhook
                </Heading>
                <FormInput
                  id="woocommerce.callbackSecretKey"
                  label="Callback Secret Key"
                  control={control}
                  variant="floating"
                  type="password"
                />
              </VStack>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  Klaviyo
                </Heading>
                <Controller
                  id="klaviyo.enabled"
                  name="klaviyo.enabled"
                  control={control}
                  defaultValue={"false"}
                  render={({
                    field: { onChange, onBlur, name, ref, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      ref={ref}
                      value={value}
                    >
                      <option value="true">Enabled</option>
                      <option value="false">Disabled</option>
                    </Select>
                  )}
                ></Controller>

                <FormInput
                  id="klaviyo.privateApiKey"
                  label="Private Api Key"
                  control={control}
                  variant="floating"
                  type="password"
                />
                <FormInput
                  id="klaviyo.payoutSentMetricName"
                  label="Payout Sent Metric Name"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="klaviyo.evaluationPassedMetricName"
                  label="Evaluation Passed Metric Name"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="klaviyo.evaluationBreachedMetricName"
                  label="Evaluation Breached Metric Name"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="klaviyo.fundedAccountBreachedMetricName"
                  label="FundedAccount Breached Metric Name"
                  control={control}
                  variant="floating"
                />
              </VStack>
            </Box>
          </HStack>
          <Divider mt={4} />
          <Flex justify={"center"}>
            <Button
              type="submit"
              w="100%"
              colorScheme={"green"}
              disabled={!isDirty}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Card>
    </Flex>
  );
}
