import { useQuery } from "@tanstack/react-query";
import { Client } from "api/axios";
import { createContext, useContext } from "react";
import { getUserRoutes } from "routes";
import { useAuth } from "./SessionMonitorProvider";
const UserRoutesContext = createContext({});
export const UserRoutesProvider = ({ children }) => {
  const { auth } = useAuth();
  let routes = getUserRoutes(auth.user);
  const passRoute = routes.find((route) => route.path === "/passes");
  const payoutRoute = routes.find((route) => route.path === "/payouts");

  const { data: passesData } = useQuery({
    queryKey: ["passesstats"],
    queryFn: () => Client(true).get(`/api/passes/stats`),
    initialData: {
      data: {
        passes: {},
      },
    },
    refetchInterval: 60000,
    enabled: passRoute?.isShow || false,
  });
  const { data: payoutsData } = useQuery({
    queryKey: ["payoutsstats"],
    queryFn: () => Client(true).get(`/api/payouts/stats`),
    initialData: {
      data: {
        payouts: {},
      },
    },
    refetchInterval: 60000,
    enabled: payoutRoute?.isShow || false,
  });

  routes = routes.map((route) => {
    if (route.path === "/payouts") {
      route.badges = [
        {
          colorScheme: "blue",
          title: "Requested",
          value: payoutsData.data.payouts?.requested || false,
        },
        {
          colorScheme: "orange",
          title: "Form Filled",
          value: payoutsData.data.payouts?.formFilled || false,
        },
      ];
    }
    if (route.path === "/passes") {
      route.badges = [
        {
          colorScheme: "purple",
          title: "New",
          value: passesData.data.passes?.new || false,
        },
        {
          colorScheme: "green",
          title: "Ready",
          value: passesData.data.passes?.ready || false,
        },
      ];
    }
    return route;
  });

  return (
    <UserRoutesContext.Provider value={{ routes }}>
      {children}
    </UserRoutesContext.Provider>
  );
};

// Create the useAuth hook
export const useUserRoutes = () => {
  const routes = useContext(UserRoutesContext);
  if (!routes) {
    throw new Error("useUserRoutes must be used within an UserRoutesProvider");
  }
  return routes;
};
